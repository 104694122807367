import { Controller } from '@hotwired/stimulus'
import ContractCalculator from '../components/ContractCalculator'

export default class extends Controller {
  static targets = [
    'basePriceInput',
    'cashPriceInput',
    'deliveryChargeInput',
    'setupFeeInput',
    'monthsInput',
    'salesTaxRateInput',
    'liabilityDamageWaiverInput',
    'contractStateInput',

    'initialPaymentInput',
    'craInput',
    'monthlyPaymentInput',
    'monthlyLdwInput',
    'monthlySalesTaxInput',
    'totalCostInput',
    'securityDepositInput',
    'earlyPurchasePercentInput'
  ]

  connect() {
    this.change()
  }

  change(event) {
    this.validateFields()
    this.updateCalculations()
    if (!event || event.target === this.monthsInputTarget || event.target === this.contractStateInputTarget) {
      this.updateEarlyPurchasePercent()
    }
  }

  validateFields() {
    const validate = (element) => {
      const value = parseFloat(element.value) || 0
      if (value < 0) {
        element.value = ''
      }
    }
    validate(this.basePriceInputTarget)
    validate(this.deliveryChargeInputTarget)
    validate(this.salesTaxRateInputTarget)
    validate(this.monthlyLdwInputTarget)
    validate(this.monthlyPaymentInputTarget)
    validate(this.securityDepositInputTarget)
    validate(this.initialPaymentInputTarget)
  }

  updateCalculations() {
    this.cashPriceInputTarget.value = this.basePriceInputTarget.value
    const initialPayment = parseFloat(this.initialPaymentInputTarget.value) || 0.0
    const monthlyRental = parseFloat(this.monthlyPaymentInputTarget.value) || 0.0
    const monthlyLDW = parseFloat(this.monthlyLdwInputTarget.value) || 0.0
    const salesTaxRate = parseFloat(this.salesTaxRateInputTarget.value) / 100 || 0
    const securityDeposit = parseFloat(this.securityDepositInputTarget.value) || 0.0
    const deliveryCharge = parseFloat(this.deliveryChargeInputTarget.value) || 0
    const setupFee = parseFloat(this.setupFeeInputTarget.value) || 0

    const monthlySubtotal = monthlyRental + monthlyLDW
    const monthlyTotal = parseFloat((monthlySubtotal * (1 + salesTaxRate)).toFixed(2))
    const monthlySalesTax = monthlyTotal - monthlySubtotal
     
    const calculatedCRA = initialPayment - monthlyTotal - securityDeposit - deliveryCharge - (setupFee * this.calculator()._taxMultiplier())

    this.craInputTarget.value = calculatedCRA.toFixed(2)
    this.monthlySalesTaxInputTarget.value = monthlySalesTax.toFixed(2)

    const months = parseFloat(this.monthsInputTarget.value)
    const totalCost = (months * monthlyRental) + calculatedCRA
    this.totalCostInputTarget.value = totalCost.toFixed(2)
  }

  updateEarlyPurchasePercent() {
    const calculatorResults = this.calculator().results()

    this.earlyPurchasePercentInputTarget.value = calculatorResults.earlyPurchase
  }

  calculator() {
    let price
    if (this.hasCashPriceInputTarget) {
      price = parseFloat(this.cashPriceInputTarget.value) || 0
    } else {
      price = 0
    }
    const deliveryCharge = parseFloat(this.deliveryChargeInputTarget.value) || 0
    const setupFee = parseFloat(this.setupFeeInputTarget.value || 0)
    const months = parseFloat(this.monthsInputTarget.value)
    const salesTaxRate = parseFloat(this.salesTaxRateInputTarget.value) / 100 || 0
    const ldwRequested = this.liabilityDamageWaiverInputTarget.checked
    const contractState = this.contractStateInputTarget.value
    return new ContractCalculator(price, deliveryCharge, setupFee, months, salesTaxRate, ldwRequested, true, contractState)
  }
}
