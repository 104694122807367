import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'contentInput']
  static values = { performOnConnect: Boolean}

  connect() {
    if (this.performOnConnectValue) {
      this.disable()
    }
  }

  enable() {
    this.inputTargets.forEach(inputTarget => inputTarget.removeAttribute('disabled'))
  }

  disable() {
    this.inputTargets.forEach(inputTarget => inputTarget.setAttribute('disabled', true))
  }

  toggleWithContent() {
    if (this.contentInputTarget.value === '') {
      this.disable()
    } else {
      this.enable()
    }
  }
}
