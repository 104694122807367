import { Controller } from '@hotwired/stimulus'
import ContractCalculator from '../components/ContractCalculator'

const output = function (value) {
  return (target) => { target.innerHTML = value.format() }
}
export default class extends Controller {
  static targets = [
    'basePriceInput',
    'cashPriceInput',
    'deliveryChargeInput',
    'setupFeeInput',
    'monthsInput',
    'salesTaxRateInput',
    'liabilityDamageWaiverInput',
    'contractStateInput',

    'lineItemDescriptionInput',
    'lineItemPriceInput',
    'lineItemQuantityInput',

    'initialPaymentOutput',
    'craOutput',
    'monthlyPaymentOutput',
    'zoneHaulFeeOutput',
    'setupFeeOutput',
    'liabilityDamageWaiverOutput',
    'proposedLiabilityDamageWaiverOutput',
    'monthlyTotalOutput',
    'paymentCountOutput',
    'totalPaidOutput',
    'earlyPurchaseOutput',
    'securityDepositOutput',

    'depositRequiredInput',

    'craInitialPayment',
    'craCra',
    'craLiabilityDamageWaiver',
    'craZoneHaulFee',
    'craSetupFee',
    'craMonthlyTotal',
    'craSecurityDeposit',

    'breakdownMonthlyPayment',
    'breakdownLiabilityDamageWaiver',
    'breakdownInitialSalesTax',
    'breakdownCra',
    'breakdownDeliveryCharge',
    'breakdownSetupFee',
    'breakdownSecurityDeposit',
    'breakdownInitialPayment',

    'craRadioButton',

    'resalePriceHidden',
    'initialPaymentHidden',
    'craHidden',
    'monthlyPaymentHidden',
    'monthlyLdwHidden',
    'proposedLdwHidden',
    'monthlySalesTaxHidden',
    'totalCostHidden',
    'securityDepositHidden',
    'earlyPurchasePercentHidden',

    'scenarioCraMonthlyPaymentHidden',
    'scenarioCraMonthlySalesTaxHidden',
    'scenarioNoCraMonthlyPaymentHidden',
    'scenarioNoCraMonthlySalesTaxHidden'
  ]

  connect() {
    this.change()
  }

  change(_event) {
    this.validateFields()
    this.updateCashCalculations()
    this.updateCalculations()
    this.updateCraCalculations()
  }

  validateFields() {
    const validate = (element) => {
      const value = parseFloat(element.value) || 0.0
      if (value < 0.0) {
        element.value = ''
      }
    }

    if (this.hasBasePriceInputTarget) {
      validate(this.basePriceInputTarget)
    }

    if (this.hasCashPriceInputTarget) {
      validate(this.cashPriceInputTarget)
      if (this.hasDeliveryChargeInputTarget) {
        validate(this.deliveryChargeInputTarget)
      }
    }
    validate(this.salesTaxRateInputTarget)
    validate(this.liabilityDamageWaiverInputTarget)
  }

  updateCashCalculations() {
    this.cashPriceInputTarget.value = this.calculateCashPrice().toString()
  }

  updateCalculations() {
    const results = this.calculator().results()
    this.initialPaymentOutputTargets.forEach(output(results.initialPayment))
    this.craOutputTargets.forEach(output(results.cra))
    this.monthlyPaymentOutputTargets.forEach(output(results.monthlyRental))
    this.liabilityDamageWaiverOutputTargets.forEach(output(results.ldw))
    this.proposedLiabilityDamageWaiverOutputTargets.forEach(output(results.proposedLdw))
    this.zoneHaulFeeOutputTargets.forEach(output(results.deliveryCharge))
    this.setupFeeOutputTargets.forEach(output(results.setupFee))
    this.monthlyTotalOutputTargets.forEach(output(results.monthlyPayment))
    this.securityDepositOutputTargets.forEach(output(results.securityDeposit))

    this.scenarioNoCraMonthlyPaymentHiddenTarget.value = results.monthlyRental
    this.scenarioNoCraMonthlySalesTaxHiddenTarget.value = results.monthlySalesTax

    if (!this.craSelected()) {
      this.updateHiddenFields(results)
      this.updateSummary(results)
      this.updateBreakdown(results)
    }
  }

  updateCraCalculations() {
    const initialPayment = this.craInitialPaymentTarget.value
    const results = this.calculator().results(initialPayment)
    this.craCraTargets.forEach(output(results.cra))
    this.craZoneHaulFeeTargets.forEach(output(results.deliveryCharge))
    this.craSetupFeeTargets.forEach(output(results.setupFee))
    this.craLiabilityDamageWaiverTargets.forEach(output(results.ldw))
    this.craMonthlyTotalTargets.forEach(output(results.monthlyPayment))
    this.craSecurityDepositTargets.forEach(output(results.securityDeposit))

    this.scenarioCraMonthlyPaymentHiddenTarget.value = results.monthlyRental
    this.scenarioCraMonthlySalesTaxHiddenTarget.value = results.monthlySalesTax

    if (this.craSelected()) {
      this.updateHiddenFields(results)
      this.updateSummary(results)
      this.updateBreakdown(results)
    }
  }

  updateBreakdown(results) {
    this.breakdownMonthlyPaymentTarget.innerHTML = results.monthlyRental.format()
    this.breakdownLiabilityDamageWaiverTarget.innerHTML = results.ldw.format()
    if (this.hasBreakdownInitialSalesTaxTarget) {
      this.breakdownInitialSalesTaxTarget.innerHTML = results.initialSalesTax.format()
    }
    if (this.hasBreakdownCraTarget) {
      this.breakdownCraTarget.innerHTML = results.cra.format()
    }
    if (this.hasBreakdownDeliveryChargeTarget) {
      this.breakdownDeliveryChargeTarget.innerHTML = results.deliveryCharge.format()
    }
    if (this.hasBreakdownSetupFeeTarget) {
      this.breakdownSetupFeeTarget.innerHTML = results.setupFee.format()
    }
    if (this.hasBreakdownSecurityDepositTarget) {
      this.breakdownSecurityDepositTarget.innerHTML = results.securityDeposit.format()
    }
    this.breakdownInitialPaymentTarget.innerHTML = results.initialPayment.format()
  }

  updateSummary(results) {
    // update the plain-text summary
    if (this.hasPaymentCountOutputTarget && this.hasTotalPaidOutputTarget && this.hasEarlyPurchaseOutputTarget) {
      this.paymentCountOutputTarget.innerHTML = this.monthsInputTarget.value
      this.totalPaidOutputTarget.innerHTML = results.totalPaid.format()
      this.earlyPurchaseOutputTarget.innerHTML = `${results.earlyPurchase}%`
    }
  }

  updateHiddenFields(results) {
    if (this.hasResalePriceHiddenTarget) {
      this.resalePriceHiddenTarget.value = results.price
    }
    if (this.hasProposedLdwHiddenTarget) {
      this.proposedLdwHiddenTarget.value = results.proposedLdw
    }
    this.initialPaymentHiddenTarget.value = results.initialPayment
    this.craHiddenTarget.value = results.cra
    this.monthlyPaymentHiddenTarget.value = results.monthlyRental
    this.monthlyLdwHiddenTarget.value = results.ldw
    this.monthlySalesTaxHiddenTarget.value = results.monthlySalesTax
    this.totalCostHiddenTarget.value = results.totalPaid
    this.securityDepositHiddenTarget.value = results.securityDeposit
    this.earlyPurchasePercentHiddenTarget.value = results.earlyPurchase
  }

  calculateCashPrice() {
    if (this.hasBasePriceInputTarget) {
      const basePrice = parseFloat(this.basePriceInputTarget.value) || 0.0

      const lineItemsPrice = this.lineItemQuantityInputTargets.reduce((total, quantity, i) => {
        const lineItemTotal = parseInt(quantity.value, 10) * parseFloat(this.lineItemPriceInputTargets[i].value)
        return total + (lineItemTotal || 0.00)
      }, 0.0)
      return (basePrice + lineItemsPrice).toFixed(2)
    }
    return parseFloat(this.cashPriceInputTarget.value) || 0.0
  }

  calculator() {
    const price = parseFloat(this.cashPriceInputTarget.value)
    const deliveryCharge = parseFloat(this.deliveryChargeInputTarget.value) || 0.0
    const setupFee = parseFloat(this.setupFeeInputTarget.value) || 0.0
    const months = parseFloat(this.monthsInputTarget.value)
    const salesTaxRate = parseFloat(this.salesTaxRateInputTarget.value) / 100 || 0.0
    const ldwRequested = this.liabilityDamageWaiverInputTarget.checked
    const depositRequired = this.depositRequiredInputTarget.value === 'true'
    const contractState = this.contractStateInputTarget.value

    return new ContractCalculator(price, deliveryCharge, setupFee, months, salesTaxRate, ldwRequested, depositRequired, contractState)
  }

  craSelected() {
    if (!this.hasCraRadioButtonTarget) {
      return false
    }

    return this.craRadioButtonTarget.checked
  }
}
